import {Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

//SERVICES
import { FunctionsService } from '../../../../services/generic/functions.service';
import {RoutingService} from "../../../../services/routing/routing.service";
import {GenericService} from "../../../../services/generic/generic.service";
import {AuthService} from "../../../../services/auth/auth.service";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { BottomSheetFilterTableComponent } from '../bottom-sheet-filter-table/bottom-sheet-filter-table.component';
import { TableViewsComponent } from '../table-views/table-views.component';
import { TableGroupsComponent } from '../table-groups/table-groups.component';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';
import { TableComponent } from '../table/table.component';
import { group } from '@angular/animations';
import { F } from '@angular/cdk/keycodes';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

interface GroupedData {
  key: string;
  data?: any[]; // Datos si es el último nivel
  subGroups?: GroupedData[]; // Subgrupos si hay más niveles
  columnName: string; // Nombre de la columna por la que se agrupa
  selectedColumn: string; // Columna seleccionada para agrupar
  level: number; // Nivel de profundidad del grupo
  indexTable: number; // Índice de la tabla en la que se encuentra
  count?: number; // Cantidad de elementos en el grupo
  evaluated?: boolean; // Indica si ya se evaluó el grupo
  expanded?: boolean; // Indica si el grupo está expandido
  pageSize?: number; // Tamaño de la página de la tabla
  pageIndex?: number; // Índice de la página de la tabla
  lastSort?: Sort; // Último ordenamiento aplicado
  dataSaved?: any[]; // Datos guardados para los params
}
interface GroupConfig {
  selectedColumn: string;
  subGroups: GroupConfig[];
  isDisabled: boolean;
}

@Component({
  selector: 'app-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss']
})
export class TableContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(TableComponent, { static: false })
  childComponent!: TableComponent;

 // @Input() functionId: number;
 @Input() structure = null;
 @Input() index: number;

 @ViewChild(MatSort) sort: MatSort;

 public idQuery: number;
 public displayedColumns = [];
 public displayedColumnsView = [];
 public displayedColumnsGroup:GroupConfig;
 public indexTable = 0;
 public search;
 public paginatorOptions = null;
 public tableData;
 public tableDataInitial;
 public arrayFiltersFields = [];
 public editingFilter = null;
 public editingView = null;
 public editingGroup = null;
 public groupsApplied = false;
 public arrayFiltersFieldsInit = [];
 private arrayFiltersFieldsEmpty = [];
 public filtersCount = 0;
 public loadedFilter = true;
 public aux = [];
 public oldValue = -1;
 public actualRow = null;
 public oldBdField = '';
 public pageIndex : number = 0;
 public pageSize = 0;
 public loaded = false;
 public loadedAfterView = false;
 public clickedRow = null;
 public bottomSheetRef = null;
 public lastSort: Sort = { active: '', direction: '' };

 public caluclated = false;

 public actual_id_functional_area;
 public idFa;
  // Este array contendrá todos los términos de búsqueda
  chipFilterArray: string[] = [];

  // Teclas que se usarán como separadores (ENTER, COMMA, etc.)
  readonly separatorKeyCodes: number[] = [ENTER, COMMA]; 
  editIndex: number | null = null;

 selectedGroupColumns: string[] = []; // Columnas seleccionadas para agrupar
 groupedData: GroupedData[] = [];

 constructor(public functionsService: FunctionsService,
             public functionsControllerService: FunctionsControllerService,
             public routingService: RoutingService,
             public genericService: GenericService,
             public conditionsClientService: ConditionsClientService,
             public authService: AuthService,
             public _bottomSheet: MatBottomSheet,
             public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']] = {};
    this.actual_id_functional_area = this.genericService.getActualInitialFA(this.structure[this.index]);
    this.idFa = this.genericService.cloneVariable(this.structure[this.index]['id_functional_area']).toString();
    this.search = this.structure[this.index]['search'] == 1;
    this.idQuery = this.structure[this.index]['id_query'];
    this.tableData = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'];
    this.tableDataInitial = this.genericService.cloneVariable(this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data']);
    this.arrayFiltersFields = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['tableFilters'];
    this.arrayFiltersFieldsEmpty = this.genericService.cloneVariable(this.arrayFiltersFields);
    if(!this.checkIfParamExists()) {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']] = {};
    }
    this.displayedColumns = this.genericService.cloneVariable(this.genericService.tableData[this.structure[this.index]['id_functional_area']]['columns']);
    if(this.tableData.length == 0 && this.structure[this.index]['form_field_empty'] == 1) this.structure[this.index]['id_functional_status_general'] = 2;
    if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'] === undefined) this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'] = [];
    if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'].length && this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'].length > 0) {
      for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters']) {
        if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['active']) {
          this.arrayFiltersFields = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['filters'];
          break;
        }
      }
    }
   
    if(!this.structure[this.index]['wasInitialized'] || this.genericService.staticHTML || this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) {
      if (this.genericService.paramControlVariables[this.actual_id_functional_area] && this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam'] && this.genericService.paramControlVariables[this.actual_id_functional_area]['params'][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']]) {
        let param = this.genericService.paramControlVariables[this.actual_id_functional_area];
        if (param['params'][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']]['output']) {
          if (param['params'][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']]['output'][0]) {
            this.oldValue = param['params'][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']]['output'][0]['value']
            this.oldBdField = param['params'][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']]['output'][0]['bd_field'];
          }
        }
      }
      
      this.aux = [];
      if(this.structure[this.index]['type'] == "checkbox") {
        this.aux.push({"Name":"0000-selectorCheckbox", "Order": -999999999999});
      }
      
      let delCol: string[] = [];
      for (let col in this.displayedColumns) {
        if(this.displayedColumns[col] == "0000-selectorCheckbox") continue;
        let found = false;
        if (!Array.isArray(this.displayedColumns[col]) && this.displayedColumns[col].split('-')[1] == this.oldBdField) this.oldBdField = this.displayedColumns[col]
        let i = 0;
        for (let ch in this.structure[this.index]['child']) {
          this.conditionsClientService.evaluateIfElements(this.structure[this.index], i);
          this.structure[this.index]['child'][ch]['bd_field'] = this.structure[this.index]['child'][ch]['bd_field'] !== null ? this.structure[this.index]['child'][ch]['bd_field'] : ""
          let bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].toLowerCase();
          bd_field = bd_field.split(',')[0];
          let idFaColumn = this.displayedColumns[col].split('-')[0];
          if (this.structure[this.index]['child'][ch]['id_functional_type'] == 9 && this.structure[this.index]['child'][ch]['id_functional_area'] == idFaColumn && this.checkIfHide(ch)) {
            found = true;
            this.aux.push({
              Name: bd_field,
              Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
              Sort: !(this.structure[this.index]['child'][ch]['sort'] == 0),
              Id: i,
              icon: this.structure[this.index]['child'][ch]['icon'],
              class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                    this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                      this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
              Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
            })
            break;
          } else if (!this.structure[this.index]['child'][ch]['bd_table'] && !this.structure[this.index]['child'][ch]['bd_field'] && !this.aux.some(e => e.Name === bd_field)) {
            found = true;
            this.aux.push({
              Name: bd_field,
              Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
              Sort: !(this.structure[this.index]['child'][ch]['sort'] == 1),
              Id: i,
              icon: this.structure[this.index]['child'][ch]['icon'],
              class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                    this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                      this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
              Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
            })
          }
          i++
        }
        if (!found) {
          delCol.push(col)
        }
      }
  
      for (let col of delCol) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(delCol[col]), 1);
      }
  
      this.aux.sort((a, b) => {
        if (a.Order === null || b.order === null) return 1;
        return (a.Order > b.Order) ? 1 : -1;
      })
  
      for (let e in this.aux) {
        this.displayedColumns[e] = this.aux[e].Name
      }
    } else {
      this.aux = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedAUX'];
      this.displayedColumns = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedDisplayedColumns'];
    }
    if(!(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      if(!this.checkIfParamExists()) {
        this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']] = {};
      }
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedAUX'] = this.aux;
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedDisplayedColumns'] = this.displayedColumns;
    }
    this.structure[this.index]['displayedColumns'] = this.displayedColumns;
    this.structure[this.index]['auxTable'] = this.aux;
    this.loaded = true;
    this.initTable0();
  }

  initTable0(createFromSratch = true) {
    if(createFromSratch) {
      if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'] == undefined) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'] = {};
      if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'] == undefined) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'] = {};
      if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'] == undefined) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'] = {};
      this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][0] = new MatTableDataSource<any>([]);
      this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][0] = new MatTableDataSource<any>([]);
    }
    if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'] && !this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'][0]) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'][0] = this.tableData;
    if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][0]) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][0].data = this.genericService.cloneVariable(this.tableData);
    if(createFromSratch) {
      this.genericService.initTableFilter(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][0]);
    } else if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][0]) {
      this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][0].data = this.genericService.cloneVariable(this.tableData);
      this.genericService.heightTableChange = true;
    }
  }
  
  doFilter() {
    this.genericService.doFilterTables(this.chipFilterArray, this.structure[this.index]);
    this.reInitGroups(this.groupedData);
    this.recalculateHeights();
    this.saveFilter();
  }

  saveFilter() {
    if(this.authService.userId > 0 && !(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa] === undefined) this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa] = {};
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['tableFilter'] = this.chipFilterArray;
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['tableMultipleFilters'] = this.arrayFiltersFields;
      this.genericService.updateParamControlFA(this.idFa, this.actual_id_functional_area, this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']);
    }
  }

  ngAfterViewInit() {
    if(!this.structure[this.index]['initApplied']) {
      this.structure[this.index]['initApplied'] = true;
      if(this.structure[this.index]['viewsInitialized']) {
        this.displayedColumnsView = this.genericService.cloneVariable(this.structure[this.index]['viewsInitialized']);
        this.structure[this.index]['viewsInitialized'] = [];
        this.applyView();
      }
    }
    
    if(!(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      if (this.checkIfParamExists()) {
        let param = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']];
        if(param && param !== undefined && param !== null) {     
          
          if (param['tableFilter']) { // Aplicar el último Search
            if(typeof(param['tableFilter']) == 'string') param['tableFilter'] = [param['tableFilter']];
            this.chipFilterArray = param['tableFilter'];
          }

          if(param['tableMultipleFilters']) { // Aplicar los últimos Filtros
            let paramTableFilters = param['tableMultipleFilters'];
            for(let i in paramTableFilters) {
              let valueOldFilter = paramTableFilters[i]['filter'];
              if(this.arrayFiltersFields[i]) {
                if(this.arrayFiltersFields[i]['type'] == 1999999) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = {fechaInicio: null, fechaFin: null};
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else if(this.arrayFiltersFields[i]['type'] == 191) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = {min: null, max: null};
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else if(this.arrayFiltersFields[i]['type'] == 124) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else {
                  let actualValues = this.arrayFiltersFields[i]['value'];
                  if(!Array.isArray(valueOldFilter)) {
                    for(let j in actualValues) {
                      if(actualValues[j] == valueOldFilter) {
                        if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                        this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                        break;
                      }
                    }
                  } else {
                    for(let j in valueOldFilter) {
                      let founded = false;
                      for(let k in actualValues) {
                        if(actualValues[k] == valueOldFilter[j]) {
                          founded = true;
                          break;
                        }
                      }
                      if(founded) {
                        if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                        let found = false;
                        for(let l in this.arrayFiltersFields[i]['filter']) {
                          if(this.arrayFiltersFields[i]['filter'][l] == valueOldFilter[j]) {
                            found = true;
                            break;
                          }
                        }
                        if(!found) {
                          this.arrayFiltersFields[i]['filter'].push(valueOldFilter[j]);
                        }
                      }
                    }
                  }
                }
              }
            }
            this.applyFilters();
          }
        }  
      }
    }

    this.loadedAfterView = true;
    if(this.structure[this.index]['groupsInitialized']) {
      this.displayedColumnsGroup = this.genericService.cloneVariable(this.structure[this.index]['groupsInitialized']);
      this.applyGroup();
    } else {
      if(this.structure[this.index]['hasGroupActive']) {
        this.genericService.initializeTable(this.structure[this.index]);
        setTimeout(() => {
          this.displayedColumnsGroup = this.genericService.cloneVariable(this.structure[this.index]['groupsInitialized']);
          this.applyGroup();
        });
      } else {
        this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].groupedDataInfo[0] = this.tableData;
        this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][0] = new MatTableDataSource<any>([]);
        this.groupsApplied = true; 
      }
    }
    this.structure[this.index]['wasInitialized'] = true;
  }

  ngOnDestroy() {
    this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']] = {};
    this.saveFilter();
  }

  checkIfHide(ch) {
    if(this.structure[this.index]['child'][ch]['id_functional_status_general'] === 2 || this.structure[this.index]['child'][ch]['hide']  == true || this.structure[this.index]['child'][ch]['hide']  == 'true' || this.structure[this.index]['child'][ch]['hide'] == 1) return false;
    else return true
  }

  public openFilterTable(from = 1) {
    this.arrayFiltersFieldsInit = this.genericService.cloneVariable(this.arrayFiltersFields);
    if(from == 0) {
      this.editingFilter = null;
      this.arrayFiltersFields = this.functionsService.cloneVariable(this.arrayFiltersFieldsEmpty);
      setTimeout(() => {
        this.openFilterTableBottomsheet(); 
      });
    } else {
      this.openFilterTableBottomsheet();
    }
  }

  public resetFiltersFunction() {
    this.filtersCount = 0;
    for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters']) {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['active'] = false;
    }
    this.applyFilters();
    this.saveFilter();
  }
  
  public openFilterTableBottomsheet() {
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetFilterTableComponent, {
      panelClass: "filter-table-generic-bottomsheet",
      data: {arrayFiltersFields: this.arrayFiltersFields, editingFilter: this.editingFilter}
    });
    this.bottomSheetRef.afterDismissed().subscribe(data => {
      if(data) {
        switch(data) {
          case 1:
            this.resetFiltersFunction();
            break;
          case 2:
            this.filtersCount = 0;
            let found = false;
            if(this.editingFilter) {
              for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters']) {
                if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['filters'] == this.arrayFiltersFields) {
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['active'] = true;
                  found = true;
                } else {
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['active'] = false;
                }
              }
            } else {
              for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters']) {
                this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][i]['active'] = false;
              }
            }
            if(!found) {
              let name = this.arrayFiltersFields['name'];
              delete this.arrayFiltersFields['name'];
              this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'].push({name: name, filters: this.genericService.cloneVariable(this.arrayFiltersFields), active: true});
            }
            this.applyFilters();
            this.saveFilter();
            break;
          case 3:
            this.arrayFiltersFields = this.genericService.cloneVariable(this.arrayFiltersFieldsInit);
            break;
        }
        this.editingFilter = null;
        this.genericService.heightTableChange = true;
      }
    });
  }

  private applyFilters() {
    this.filtersCount = 0;
    this.loadedFilter = false;
    let newData = this.genericService.cloneVariable(this.tableDataInitial);
    let filters = this.arrayFiltersFields;
    for(let i in filters) {
      if(filters[i]['type'] == 124) {
        if(filters[i]['filter'] === 0 || filters[i]['filter'] === 1) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = newData[j][filters[i]['nameOriginal']];
            let filterValue = filters[i]['filter'];
            if(filters[i]['filter'] === 0) {
              if(!Array.isArray(originValue)) {
                if(filterValue == originValue) newData2.push(newData[j]);
              } else {
                for(let l in originValue) {
                  if(filterValue == originValue[l]) {
                    newData2.push(newData[j]);
                    break;
                  }
                }
              }
            } else {
              if(!Array.isArray(originValue)) {
                if(0 < originValue) newData2.push(newData[j]);
              } else {
                for(let l in originValue) {
                  if(0 < originValue[l]) {
                    newData2.push(newData[j]);
                    break;
                  }
                }
              }
            }
          }
          newData = newData2;
        }
      } else if(filters[i]['type'] == 191) {
        let max = null;
        if(filters[i]['filter'].max) max = filters[i]['filter'].max;
        let min = null;
        if(filters[i]['filter'].min) min = filters[i]['filter'].min;
        if(min > 0 || max > 0) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = this.prepareAsNumber(newData[j][filters[i]['nameOriginal']]);
            if((!min || min == 0 || originValue >= min) && (!max || max == 0 || originValue <= max)) {
              newData2.push(newData[j]);
            }
          }
          newData = newData2;
        }
      } else if(filters[i]['type'] == 1999999) {
        let fechaInicio = null;
        if(filters[i]['filter'].fechaInicio) {
          fechaInicio = new Date(filters[i]['filter'].fechaInicio).setHours(2,0,0,0);
        }
        let fechaFin = null;
        if(filters[i]['filter'].fechaFin) {
          fechaFin = new Date(filters[i]['filter'].fechaFin).setHours(2,0,0,0);
        }
        if(fechaInicio != null || fechaFin != null) {
          let newData2 = [];
          ++this.filtersCount
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = newData[j][filters[i]['nameOriginal']];
            if(!Array.isArray(originValue)) {
              originValue = this.tratarDate(originValue, fechaInicio);
              if((fechaInicio == null || fechaInicio <= originValue) && (fechaFin == null || originValue <= fechaFin)) newData2.push(newData[j]);
            } else {
              for(let l in originValue) {
                originValue = this.tratarDate(originValue, fechaInicio);
                if((fechaInicio == null || fechaInicio <= originValue) && (fechaFin == null || originValue <= fechaFin)) {
                  newData2.push(newData[j]);
                  break;
                }
              }
            }
          }
          newData = newData2;
        }
      } else {
        if(filters[i]['filter'].length > 0) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = newData[j][filters[i]['nameOriginal']];
            let filterValue = filters[i]['filter'];
            nextRowToFilter:
            if(!Array.isArray(filterValue)) {
              if(!Array.isArray(originValue)) {
                if(originValue && filterValue && filterValue.trim() == originValue.trim()) {
                  newData2.push(newData[j]);
                  break nextRowToFilter;
                }
              } else {
                for(let l in originValue) {
                  if(originValue[l] && filterValue && filterValue.trim() == originValue[l].trim()) {
                    newData2.push(newData[j]);
                    break nextRowToFilter;
                  }
                }
              }
            } else {
              for(let k in filterValue) {
                if(!Array.isArray(originValue)) {
                  if(originValue && filterValue[k] && filterValue[k].trim() == originValue.trim()) {
                    newData2.push(newData[j]);
                    break nextRowToFilter;
                  }
                } else {
                  for(let l in originValue) {
                    if(originValue[l] && filterValue[k] && filterValue[k].trim() == originValue[l].trim()) {
                      newData2.push(newData[j]);
                      break nextRowToFilter;
                    }
                  }
                }
              }
            }
          }
          newData = newData2;
        }
      }
    }
    newData.sort(function (a, b) {
      if (a.originalRowID > b.originalRowID) return 1;
      if (a.originalRowID < b.originalRowID) return -1;
      return 0;
    });
    this.tableData = newData;
    console.log(this.tableData, 'tableData');
    this.applyGroup(true);
    this.doFilterInit(0);
    this.loadedFilter = true;
  }

  private tratarDate(originValue, fechaInicio) {
    if(originValue == null || originValue === undefined) {
      if(fechaInicio != null) originValue = 0;
      else originValue = 999999999;
    } else {
      originValue = originValue.split(" ")[0];
      originValue = originValue.split(",")[0];
      let values = originValue.split("-");
      if(values[2] && values[2].length == 4) originValue = values[2] + "-" + values[1] + "-" + values[0];
      originValue = new Date(originValue);
      originValue = originValue.getTime();
    }
    return originValue;
  }

  private prepareAsNumber(value) {
    if(isNaN(value)) {
      value = value.split(',')[0];
      value = value.replaceAll('.', '');
      value = value.replaceAll(' ', '');
      value = value.replaceAll('€', '');
      value = value.replaceAll('$', '');
    }
    return value;
  }
  
  checkIfParamExists() {
    return this.genericService.checkIfParamExists(this.actual_id_functional_area, this.structure[this.index]);
  }

  selectFilters(filter, index) {
    let cnt = 0;
    for(let filt of this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters']) {
      if(cnt != index) {
        filt.active = false;
      }
      ++cnt;
    }
    filter.active = true;
    this.arrayFiltersFields = this.functionsService.cloneVariable(filter.filters);
    this.applyFilters();
    this.saveFilter();
  }

  deleteFilter(index) {
    if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'][index]['active']) {
      this.arrayFiltersFields = this.functionsService.cloneVariable(this.arrayFiltersFieldsEmpty);
      setTimeout(() => {
        this.applyFilters();
        this.genericService.heightTableChange = true;
      });
    }
    this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['allFilters'].splice(index, 1);
    this.saveFilter();
  }
  
  editFilter(filter) {
    this.editingFilter = filter;
    this.openFilterTable();
  }

  deleteView(index) {
    if(this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][index]['active']) {
      this.genericService.cleanView(this.structure[this.index]);
    }
    this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'].splice(index, 1);
    this.saveFilter();
  }
  
  deleteGroup(index) {
    this.resetGroupsFunction();
    setTimeout(() => {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'].splice(index, 1);
      this.saveFilter();
    });
  }

  selectView(view) {
    for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views']) {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][i]['active'] = false;
    }
    view.active = true;
    this.structure[this.index]['hasViewActive'] = true;
    this.displayedColumnsView = this.genericService.cloneVariable(view.views);
    setTimeout(() => {
      this.applyView();
    });
    this.saveFilter();
  }
  
  selectGroup(group) {
    for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups']) {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['active'] = false;
    }
    group.active = true;
    this.structure[this.index]['hasGroupActive'] = true;
    this.structure[this.index]['groupActive'] = group;
    this.displayedColumnsGroup = this.genericService.cloneVariable(group.groups);
    setTimeout(() => {
      this.applyGroup();
    });
    this.saveFilter();
  }
  
  editView(view) {
    this.editingView = view;
    this.openViewsTable();
  }
  
  editGroup(group) {
    this.editingGroup = group;
    this.openGroupsTableBottomsheet();
  }
  
  applyColumns(columns) {
    this.displayedColumnsView = columns;
    this.applyView();
  }

  public openViewsTable(from = 1) {
    if(from == 0) {
      this.editingView = null;
      this.displayedColumnsView = this.genericService.cloneVariable(this.genericService.tableData[this.structure[this.index]['id_functional_area']]['columns']);
      setTimeout(() => {
        this.openViewsTableBottomsheet(); 
      });
    } else {
      this.openViewsTableBottomsheet();
    }
  }

  public resetViewsFunction() {
    for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views']) {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][i]['active'] = false;
    }
    this.genericService.cleanView(this.structure[this.index]);
    this.structure[this.index]['hasViewActive'] = false;
    this.saveFilter();
  }

  public resetGroupsFunction() {
    for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups']) {
      this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['active'] = false;
    }
    this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].dataSourceTables = {};
    this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].dataSourceTablesInit = {};
    this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].groupedDataInfo = {};
    this.initTable0(true);
    this.cleanGroup();
    this.structure[this.index]['groupActive'] = {};
    this.structure[this.index]['hasGroupActive'] = false;
    this.saveFilter();
  }

  public openViewsTableBottomsheet() {
    this.bottomSheetRef = this._bottomSheet.open(TableViewsComponent, {
      panelClass: "filter-table-generic-bottomsheet",
      data: {columns: this.genericService.cloneVariable(this.genericService.tableData[this.structure[this.index]['id_functional_area']]['columns']), editingView: this.editingView}
    });
    this.bottomSheetRef.afterDismissed().subscribe(data => {
      if(data) {
        this.displayedColumnsView = data.columns;
        switch(data.from) {
          case 1:
            this.resetViewsFunction();
            break;
          case 2:
            if(!this.editingView) {
              for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views']) {
                this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][i]['active'] = false;
              }
              this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'].push({name: data.name, views: data.columns, active: true});
            } else {
              for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views']) {
                if(this.editingView['views'] != this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][i]['views']) {
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][i]['active'] = false;
                } else {
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['views'][i]['active'] = true;
                }
              }
            }
            this.structure[this.index]['hasViewActive'] = true;
            this.applyView();
            this.saveFilter();
            break;
          case 3:
            break;
        }
        this.editingView = null;
        this.genericService.heightTableChange = true;
      }
    });
  }

  public openGroupsTableBottomsheet() {
    this.bottomSheetRef = this._bottomSheet.open(TableGroupsComponent, {
      panelClass: "filter-table-generic-bottomsheet",
      data: {columns: this.genericService.cloneVariable(this.displayedColumns), editingGroup: this.editingGroup}
    });
    this.bottomSheetRef.afterDismissed().subscribe(data => {
      if(data) {
        this.displayedColumnsGroup = data.groups;
        switch(data.from) {
          case 1:
            this.resetGroupsFunction();
            break;
          case 2:
            if(!this.editingGroup) {
              for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups']) {
                this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['active'] = false;
              }
              this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'].push({name: data.name, groups: data.groups, active: true});
              this.structure[this.index]['groupActive'] = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'].length - 1];
              this.structure[this.index]['hasGroupActive'] = true;
              this.applyGroup();
            } else {
              for(let i in this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups']) {
                if(this.editingGroup['groups'] != this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['groups']) {
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['active'] = false;
                } else {
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['active'] = true;
                  this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i]['groupSavedData'] = {};
                  this.structure[this.index]['groupActive'] = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['groups'][i];
                  this.structure[this.index]['hasGroupActive'] = true;
                  this.applyGroup();
                }
              }
            }
            this.saveFilter();
            break;
          case 3:
            break;
        }
        this.editingGroup = null;
        setTimeout(() => {
          this.genericService.heightTableChange = true;
        }, 1000);
      }
    });
  }

  applyView() {
    this.genericService.applyViewTable(this.displayedColumnsView, this.structure[this.index]);
  }

  resetFilters() {
    let filters = this.genericService.cloneVariable(this.arrayFiltersFields);
    for(let i in filters){
      filters[i]['filter'] = [];
    }
    this.arrayFiltersFields = filters;
    this.resetFiltersFunction();
    this.editingFilter = null;
    this.genericService.heightTableChange = true;
  }

  resetViews() {
    this.resetViewsFunction();
    this.editingView = null;
    this.genericService.heightTableChange = true;
  }

  resetGroups() {
    this.resetGroupsFunction();
    this.editingGroup = null;
    this.genericService.heightTableChange = true;
  }

  applyGroup(refreshObjects = false) {
    if (this.displayedColumnsGroup && this.displayedColumnsGroup['selectedColumn'] && this.structure[this.index]['hasGroupActive']) {
      if(this.structure[this.index]['groupActive']['groupSavedData'] === undefined) this.structure[this.index]['groupActive']['groupSavedData'] = {};
      this.groupedData = this.groupData(this.genericService.cloneVariable(this.tableData), this.displayedColumnsGroup, this.structure[this.index]['groupActive']['groupSavedData'], refreshObjects);
      setTimeout(() => {
        this.genericService.doFilterTables(this.chipFilterArray, this.structure[this.index]);
      });
    } else {
      this.initTable0(false);
    }
    this.groupsApplied = true;
    this.genericService.heightTableChange = true;
    /*setTimeout(() => {
      this.doFilterInit(0);
    });*/
  }

  cleanGroup() {
    this.displayedColumnsGroup = null;
    this.indexTable = 0;
    this.genericService.doFilterTables(this.chipFilterArray, this.structure[this.index]);
  }
  
  groupData(data: any[], groupConfig: GroupConfig, groupActive, refreshObjects, level = 1): GroupedData[] {
    const column = groupConfig.selectedColumn;
    let columnNameSplit = groupConfig.selectedColumn.split('-');
    if (!column || !columnNameSplit[1]) {
      if(!this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][this.indexTable] || refreshObjects) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][this.indexTable] = new MatTableDataSource<any>([]);
      if(!this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'][this.indexTable] || refreshObjects) this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'][this.indexTable] = data;  
      this.genericService.doFilterTables(this.chipFilterArray, this.structure[this.index]);
    }
    let columnName = '';
    let idFa = null;
    if(columnNameSplit[0]) idFa = this.genericService.findElementWithId(columnNameSplit[0], false, false, true);
    if(idFa && idFa['label']) columnName = idFa['label'];
    
    const groups = data.reduce((result, item) => {
      let key = this.getNestedPropertyValue(item, column) || 'Sin especificar';
      
      if (!Array.isArray(key)) {
        key = [key];
      }
    
      key.forEach(k => {
        if (!result[k]) {
          result[k] = [];
        }
        result[k].push(item);
      });
    
      return result;
    }, {});
    
    const groupKeys = Object.keys(groups);
    
    return groupKeys.map(key => {
      const groupDataForKey = groups[key];
      this.indexTable++;
      this.search = true;
      console.log(groupActive, 'groupActive', key, level, groupConfig.selectedColumn);
      let dataSaved = this.findGroupsTablesBasedOnSubgroupFunction(groupActive, key, level, groupConfig.selectedColumn);
      console.log(dataSaved, 'dataSaved');
      const groupDataItem: GroupedData = { key, columnName, indexTable: this.indexTable, selectedColumn: groupConfig.selectedColumn, level, dataSaved };
    
      if (groupConfig.subGroups && groupConfig.subGroups.length > 0) {
        groupDataItem.subGroups = [];
        for (let i = 0; i < groupConfig.subGroups.length; i++) {
          const subGroupConfig = groupConfig.subGroups[i];
          if (subGroupConfig.selectedColumn === undefined) {
            groupConfig.subGroups.splice(i, 1);
            i--; // Ajustar el índice después de eliminar un elemento
            continue; // Saltar a la siguiente iteración
          }
          const subGroupData = this.groupData(groupDataForKey, subGroupConfig, dataSaved, refreshObjects, level + 1);
          groupDataItem.subGroups = groupDataItem.subGroups.concat(subGroupData);
        }
      } else {
        if (!this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'][this.indexTable || refreshObjects]) {
          this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['groupedDataInfo'][this.indexTable] = groupDataForKey;
        }
        if (!this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][this.indexTable] || refreshObjects) {
          this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][this.indexTable] = new MatTableDataSource<any>([]);
        }
        if (!this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][this.indexTable] || refreshObjects) {
          this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][this.indexTable] = new MatTableDataSource<any>([]);
          this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][this.indexTable].data = groupDataForKey;
          this.genericService.initTableFilter(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][this.indexTable]);
        }
      }
      return groupDataItem;
    });    
  }

  getNestedPropertyValue(obj: any, propertyPath: string): any {
    // Obtener el ID del propertyPath (parte antes del guión)
    const propertyId = propertyPath.split('-')[0];
  
    // Buscar la clave en el objeto que tenga el mismo ID
    const matchingKey = Object.keys(obj).find(key => {
      const keyId = key.split('-')[0];
      return keyId === propertyId;
    });
  
    if (matchingKey) {
      return obj[matchingKey];
    } else {
      return null; // No se encontró una clave con el mismo ID
    }
  }

  recalculateHeights() {
    this.structure[this.index]['calculateHeightsInit'] = null;
    this.genericService.heightTableChange = true;
  }

  hasInitCounter(group, variable) {
    let response = this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']] && this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']][variable] && this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']][variable][group.indexTable] && this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']][variable][group.indexTable].filteredData && this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']][variable][group.indexTable].filteredData.length !== undefined && this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']][variable][group.indexTable].filteredData.length > 0;
    return response;
  }

  doFilterInit(from = 1) {
    if(!this.structure[this.index]['doFilterInit'] || from == 0) {
      this.structure[this.index]['doFilterInit'] = true;
      setTimeout(() => {
        this.doFilter();
      });
    }
    return true;
  }

  getNumbersTable(group) {
    if(group.subGroups) {
      return this.getNumbersTableChild(group);
    } else {
      group.count = 0;
      if(this.hasInitCounter(group, 'dataSourceTables')) {
        group.count = this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][group.indexTable].filteredData.length;
      } else if(this.hasInitCounter(group, 'dataSourceTablesInit')) {
        group.count = this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTablesInit'][group.indexTable].filteredData.length;
      }
      group.evaluated = true;
      return group.count > 0;
    }
  }

  getNumbersTableChild(group) {
    group.count = 0;
    group.evaluated = false;
    for(let g of group.subGroups) {
      if(g.count) group.count += g.count;
      if(g.evaluated) group.evaluated = true;
    }
    return group.count > 0 || !group.evaluated;
  }

  checkNoValuesFiltersGroups(groups) {
    let returnVal = true;
    for(let i in groups) {
      if(groups[i]['count'] > 0 || !groups[i]['evaluated']) {
        returnVal = false;
        break;
      }
    }
    return returnVal;
  }

  reInitGroups(groups) {
    for(let i in groups) {
      groups[i].evaluated = false;
      if(groups[i].subGroups) {
        this.reInitGroups(groups[i].subGroups);
      }
    }
  }

  triggerExport() {
    if (this.childComponent) {
      this.childComponent.exportTableAsExcel();
    }
  }

  clickPanel(group, expanded) {
    if(group['dataSaved'] && group['dataSaved'].expanded == !expanded) {
      group['dataSaved'].expanded = expanded;
      this.genericService.updateParamControlFA(this.idFa, this.actual_id_functional_area, this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']);
    }
  }

  findGroupsTablesBasedOnSubgroupFunction(groupParam, key, level, selectedColumn) {
    if(groupParam.level && groupParam.selectedColumn && groupParam.rows) {
      if(groupParam.level && groupParam.level == level && groupParam.selectedColumn && groupParam.selectedColumn == selectedColumn && groupParam['rows']) {
        if(groupParam['rows'][key] === undefined) groupParam['rows'][key] = {expanded: false};
        return groupParam['rows'][key];
      } else if(groupParam.level < level) {
        // Se da por hecho que siempre es un level más del esperado, pues en ese caso es que se trata del hijo. Como se está generando, siempre es el hjo, no hay nietos.
        groupParam.subGroup = {};
        let aux = this.findGroupsTablesBasedOnSubgroupFunction(groupParam.subGroup, key, level, selectedColumn);
        if(aux) return aux;
      }
    } else {
      groupParam['level'] = level;
      groupParam['selectedColumn'] = selectedColumn;
      groupParam['rows'] = {};
      groupParam['rows'][key] = {expanded: false};
      return groupParam['rows'][key];
    }
  }
  /**
   * Se dispara cuando el usuario introduce un término y presiona ENTER.
   * Añade un nuevo chip y llama a doFilter().
   */
  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.chipFilterArray.push(value);
      this.doFilter();
    }
    // Limpiamos el input
    event.chipInput!.clear();
  }

  /**
   * Elimina un chip del array y llama a doFilter().
   */
  removeChip(filter: string): void {
    const index = this.chipFilterArray.indexOf(filter);
    if (index >= 0) {
      this.chipFilterArray.splice(index, 1);
      this.doFilter();
    }
  }

  /**
   * Activa el modo edición en el chip con índice 'index'.
   * Usamos setTimeout para asegurar el foco en el input.
   */
  editChip(index: number): void {
    this.editIndex = index;
    setTimeout(() => {
      const inputElement = document.querySelector(
        '.edit-chip-input'
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  }

  /**
   * Finaliza la edición y llama a doFilter().
   */
  finishEditing(index: number): void {
    this.editIndex = null;
    this.doFilter();
  }

  /**
   * Maneja las pulsaciones de tecla dentro del input de edición.
   * - Si pulsamos Enter, finaliza la edición.
   * - Si pulsamos Backspace y el texto está vacío, elimina el chip.
   */
  onEditKeyDown(event: KeyboardEvent, index: number): void {
    // Evitar propagación para que el mat-chip no maneje el evento
    event.stopPropagation();

    if (event.key === 'Enter') {
      // Finalizar edición al presionar Enter
      this.finishEditing(index);
    } else if (event.key === 'Backspace') {
      // Si el texto está vacío y se presiona Backspace, elimina el chip
      if (this.chipFilterArray[index] === '') {
        this.removeChip(this.chipFilterArray[index]);
        this.editIndex = null;
      }
    }
  }
  
  /**
   * trackBy para evitar re-renderizados de la lista entera al editar un elemento.
   */
  trackByFn(index: number, item: string): number {
    return index;
  }
}