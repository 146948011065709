<div style="max-height: 100%; overflow: auto;">
    <mat-form-field appearance="outline">
        <mat-label>Show options</mat-label>
        <mat-select [(ngModel)]="showOptions" (selectionChange)="changeShowOptions()">
            <mat-option value="1">Only Primary Keys</mat-option>
            <mat-option value="2">Only Primary Keys and Foreign Keys</mat-option>
            <mat-option value="3">All fields</mat-option>
            <mat-option value="4">Only tables</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Zoom In/Out</mat-label>
        <mat-select [(ngModel)]="myZoom" (selectionChange)="changeMyZoom()">
            <mat-option value="1000">1000</mat-option>
            <mat-option value="2000">2000</mat-option>
            <mat-option value="3000">3000</mat-option>
            <mat-option value="4000">4000</mat-option>
            <mat-option value="5000">5000</mat-option>
            <mat-option value="6000">6000</mat-option>
            <mat-option value="7000">7000</mat-option>
            <mat-option value="8000">8000</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="parent-mermaid" *ngIf="loaded">
        <div id="mermaid-diagram" [ngStyle]="{'width.px': myZoom, 'overflow': 'auto'}" [innerHtml]="mermaidDefinition"></div>
    </div>
</div>