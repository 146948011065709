import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GenericService } from '../generic.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { Observable, Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isArray } from 'rxjs/internal-compatibility';


@Injectable({
  providedIn: 'root'
})
export class GenericCreatorService {
  public isStatic = false;
  public hideOptionsCreator = true;
  public arrayElementsGenericCreator = [];
  public tmpId = 0;
  public currentRecordIndex: number;
  public arrayElementsGenericCreatorRecord = []
  public arrayElementsTipo = [];
  public functional_subtype = [];
  public defaultArray = [];
  public functionalStatus = [];
  public class_angular_icon = [];
  public angular_classes = [];
  public custom_classes = [];
  public filteredFunctionalStatus = [];
  public filteredFunctionalTypes = [];
  public languages = [];
  public functionalSubtypes = [];
  public lastId_functional_type = -1;
  public currentElement: any;
  public currentIdTmpParent: any;
  public actualSelectedElement: any;
  public actualSelectedElementDSB: any;
  public isSelectingElement = false;
  public activeTabIndex = 0;
  public expandedFloatingIndex = null;
  public expandedFloatingElement: any;
  public listElementOptionToggle = false;
  public actualSelectedFunctionalTypeChild: any;
  public copiedElement: any;
  currentLanguage = 1;
  public defaultLanguageElement: any;
  public get_all_columns_excluding_data_type_fa_restrictions: any;
  public get_all_columns_fa_restrictions: any;
  public queries: any;
  public relations: any;
  public db: any;
  public functional_type: any;
  public id_query: any;
  public id_db: any;
  public bd_table: any;
  public bd_field: any;
  public id_functional_type: any;
  public id_product_mkt: any;
  public id_product_mkt_no: any;
  public id_condition: any;
  public condition_internal_routing: any;
  public condition_session_active: any;
  public class = '';
  public class_custom_original: any;
  public permissions_group: any;
  public functions: any;
  public company_type: any;
  public data_type: any;
  public admin_area: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public arrayFloatingElements = [];
  public actualSelectedSubElement: any;
  public isSubElement = false;
  public isAddingElement = false;
  public expandedIndex = 1;
  public color_card = '#ffffff';
  public is_preloaded_element = false;
  public hideOptions = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 },
    { label: 'Custom', value: 2 }
  ]
  filteredOptions: Observable<string[]>;
  companyTypeOptions: Observable<string[]>;
  companyTypeOptionsRestriction: Observable<string[]>;
  idProductMktOptions: Observable<string[]>;
  idProductMktNoOptions: Observable<string[]>;
  idConditionOptions: Observable<string[]>;
  conditionInternalRoutingOptions: Observable<string[]>;
  profilePermissionOptions: Observable<string[]>;
  idFunctionOptions: Observable<string[]>;
  idConditionBadgeOptions: Observable<string[]>;
  idQueryOptions: Observable<string[]>;
  idQueryOptionsNGFOR: Observable<string[]>;
  idRelationsOptions: Observable<string[]>;
  idRelationsOptionsNGFOR: Observable<string[]>;
  containerForm: any;
  actualSelectedElementObs$: Observable<any>;
  private actualSelectedElementObsSubject = new Subject<any>();

  loadTables = {};

  tablesOptions: Observable<string[]>;
  fieldsOptions: Observable<string[]>;

  tables = {};
  fields = {};
  filteredOptionsLengthBdTable = 0;
  filteredOptionsLengthBdField = 0;

  constructor(public genericService: GenericService,
    public formBuilder: FormBuilder,
    public functionsService: FunctionsService,
    public endpointService: EndpointService) {
    this.containerForm = this.formContainer();
    this.actualSelectedElementObs$ = this.actualSelectedElementObsSubject.asObservable();
  }

  setActualSelectedElement(data: any) {
    this.actualSelectedElementObsSubject.next(data);
  }

  checkIndexClose(index) {
    if (index === this.expandedIndex) {
      this.expandedIndex = 0
    }
  }

  formBuilderCustom(element) {
    console.log('=====', element)
    element['bd_table_autocomplete'] = { internal_name: this.genericService.cloneVariable(element['bd_table']) };
    element['bd_field_autocomplete'] = { internal_name: this.genericService.cloneVariable(element['bd_field']) };
    element['id_product_mkt_autocomplete'] = { id_product: this.genericService.cloneVariable(element['id_product_mkt']), internal_name: this.genericService.cloneVariable(element['product_mkt_internal_name']) };
    element['id_product_mkt_no_autocomplete'] = { id_product: this.genericService.cloneVariable(element['id_product_mkt_no']), internal_name: this.genericService.cloneVariable(element['product_mkt_no_internal_name']) };
    let condition_internal_routing = this.genericService.cloneVariable(element['condition_internal_routing']);
    if (condition_internal_routing) condition_internal_routing = condition_internal_routing.replace("/", "");
    element['condition_internal_routing_autocomplete'] = { internal_routing: condition_internal_routing };
    element['id_condition_autocomplete'] = { id_condition: this.genericService.cloneVariable(element['id_condition']), internal_name: this.genericService.cloneVariable(element['condition_internal_name']) };
    element['id_function_autocomplete'] = { id_function: this.genericService.cloneVariable(element['id_function']), internal_name: this.genericService.cloneVariable(element['function_internal_name']) };
    element['id_condition_badge_autocomplete'] = { id_condition: this.genericService.cloneVariable(element['id_condition_badge']), internal_name: this.genericService.cloneVariable(element['condition_badge_internal_name']) };
    element['id_query_autocomplete'] = { id_query: this.genericService.cloneVariable(element['id_query']), internal_name: this.genericService.cloneVariable(element['query_internal_name']) };
    element['id_query_ngfor_autocomplete'] = { id_query_ng_for: this.genericService.cloneVariable(element['id_query_ng_for']), internal_name: this.genericService.cloneVariable(element['query_ngfor_internal_name']) };
    element['id_table_relations_autocomplete'] = { id_table_relations: this.genericService.cloneVariable(element['id_table_relations']), internal_name: this.genericService.cloneVariable(element['table_relations_internal_name']) };
    element['id_table_relations_ngfor_autocomplete'] = { id_table_relations_ng_for: this.genericService.cloneVariable(element['id_table_relations_ng_for']), internal_name: this.genericService.cloneVariable(element['table_relations_ngfor_internal_name']) };
    let hideVar = 0;
    if(element.hide == 1) hideVar = 1;
    if(element.hide_xs == 1 || element.hide_s == 1 || element.hide_m == 1 || element.hide_l == 1 || element.hide_xl == 1) {
      if(element.hide_xs == 1 && element.hide_s == 1 && element.hide_m == 1 && element.hide_l == 1 && element.hide_xl == 1) {
        hideVar = 1;
      } else {
        hideVar = 2;
      }
    }
    if(!element.id_functional_status_initial) element.id_functional_status_initial = element.id_functional_status_general;
    return this.formBuilder.group({
      id_functional_area: [element.id_functional_area],
      id_functional_type: [element.id_functional_type, Validators.required],
      id_functional_status_initial: [!element.id_functional_status_initial || element.id_functional_status_initial == 0 ? 1 : +element.id_functional_status_initial, Validators.required],
      type: [element.type, this.getValidation('type', element.id_functional_type)],
      label: [element.label, this.getValidation('label', element.id_functional_type)],
      class_custom_original_creator: [element.class_custom_original_creator],
      class: [element.class],
      style: [element.style],
      hide: [hideVar],
      hide_xs: [element.hide_xs == 1 ? 1 : null],
      hide_s: [element.hide_s == 1 ? 1 : null],
      hide_m: [element.hide_m == 1 ? 1 : null],
      hide_l: [element.hide_l == 1 ? 1 : null],
      hide_xl: [element.hide_xl == 1 ? 1 : null],
      width_general: [element.width_general, [Validators.max(100), Validators.min(0)]],
      isAutomaticResponsive: [false],
      width_xs: [element.width_xs, [Validators.max(100), Validators.min(0)]],
      width_s: [element.width_s, [Validators.max(100), Validators.min(0)]],
      width_m: [element.width_m, [Validators.max(100), Validators.min(0)]],
      width_l: [element.width_l, [Validators.max(100), Validators.min(0)]],
      width_xl: [element.width_xl, [Validators.max(100), Validators.min(0)]],
      id_product_mkt_autocomplete: [element.id_product_mkt_autocomplete],
      id_product_mkt_no_autocomplete: [element.id_product_mkt_no_autocomplete],
      id_condition_autocomplete: [element.id_condition_autocomplete],
      condition_internal_routing_autocomplete: [element.condition_internal_routing_autocomplete],
      condition_session_active: [element.condition_session_active],
      dev: [element.dev == 1 ? 1 : null],
      dev_no: [element.dev_no == 1 ? 1 : null],
      buttonType: ['', this.getValidation('buttonType', element.id_functional_type)],
      tabType: ['', this.getValidation('tabType', element.id_functional_type)],
      min_rows: [element.min_rows],
      max_rows: [element.max_rows],
      max_lengths: [element.max_lengths, this.getValidation('max_lengths', element.id_functional_type)],
      form_field_empty: [element.form_field_empty],
      autoload_selection: [element.autoload_selection],
      profiles_permissions: [element.profiles_permissions],
      permissions_group_functional_area: [[]],
      tooltip: [element.tooltip],
      icon: [element.icon],
      icon_type_original: [element.icon_type_original],
      badge: [element.badge],
      id_condition_badge_autocomplete: [element.id_condition_badge_autocomplete],
      id_function_autocomplete: [element.id_function_autocomplete],
      duplicate_id_functional_area: [element.duplicate_id_functional_area],
      internal_routing: [element.internal_routing],
      internal_routing_id_functional_area: [element.internal_routing_id_functional_area],
      text: [element.text, this.getValidation('text', element.id_functional_type)],
      url: [element.url, this.getValidation('url', element.id_functional_type)],
      iframe_fullscreen: [element.iframe_fullscreen],
      id_query_autocomplete: [element.id_query_autocomplete, this.getValidation('id_query_autocomplete', element.id_query_autocomplete)],
      id_query_ngfor_autocomplete: [element.id_query_ngfor_autocomplete],
      id_table_relations_autocomplete: [element.id_table_relations_autocomplete],
      id_table_relations_ngfor_autocomplete: [element.id_table_relations_ngfor_autocomplete],
      show_total_graphs: [element.show_total_graphs],
      carousel_num_visible: [element.carousel_num_visible],
      carousel_num_scroll: [element.carousel_num_scroll],
      carousel_circular: [element.carousel_circular],
      expansion_start_opened: [element.expansion_start_opened],
      search: [element.search],
      pagination: [element.pagination],
      canExportTables: [element.canExportTables],
      internal_name: [element.internal_name],
      id_db: [element.id_db],
      bd_table_autocomplete: [element.bd_table_autocomplete],
      bd_field_autocomplete: [element.bd_field_autocomplete],
      close_dialog_out: [element.close_dialog_out],
      description: [element.description],
      param: [element.param],
      param_intern: [element.param_intern],
      sort: [element.sort],
      multiple: [element.multiple],
      carousel_num_scroll_s: [element.carousel_num_scroll_s],
      carousel_num_scroll_xs: [element.carousel_num_scroll_xs],
      carousel_num_visible_s: [element.carousel_num_visible_s],
      carousel_num_visible_xs: [element.carousel_num_visible_xs],
      show_buttons_carousel: [element.show_buttons_carousel],
      color_card: [element.color_card],
      is_preloaded_element: [element.is_preloaded_element == 1 ? 1 : null],
      hide_booleans_false: [element.hide_booleans_false],
      align_vertical: [element.align_vertical],
      align_horizontal: [element.align_horizontal],
      text_align: [element.text_align],
      height: [element.height],
    })
  }

  formDataValidity(obj: any) {
    console.log("formDataValidityformDataValidity", obj)
    return this.formBuilder.group({
      id_data_type: [obj['id_data_type'], Validators.required],
      id_admin_area: [obj['id_admin_area'], Validators.required],
      id_company_type: [obj['id_company_type'], Validators.required],
      companyTypes: [obj['companyTypes'], Validators.required],
      id_functional_status_general: [obj['id_functional_status'], Validators.required]
    })
  }

  formRestriction(obj: any) {
    obj['activation_date'] = this.functionsService.formatDate(obj['activation_date'], false);
    return this.formBuilder.group({
      activation_date: [obj['activation_date'], Validators.required],
      id_admin_area: [obj['id_admin_area'], Validators.required],
      id_company_type: [obj['id_company_type'], Validators.required],
      companyTypes: [obj['companyTypes'], Validators.required],
      id_functional_status_general: [obj['id_functional_status_general'], Validators.required]
    })
  }

  formContainer() {
    return this.formBuilder.group({
      id_query: [''],
      id_db: [''],
      bd_table: [''],
    })
  }

  // return required validation based on functional type
  getValidation(formField: any, type: any) {
    if (!type) return null;
    switch (formField) {
      case 'type':
        if (type == 5 || type == 101
          || type == 14 || type == 99
          || type == 96 || type == 9) {
          return Validators.required
        }
        break;

      case 'label':
        if (type == 5 || type == 14
          || type == 4 || type == 105
          || type == 7 || type == 95
          || type == 9 || type == 86
          || type == 6 || type == 12
          || type == 15 || type == 113
          || type == 121) {
          return Validators.required
        }
        break;

      case 'buttonType':
        if (type == 5) {
          return Validators.required
        }
        break;

      case 'text':
        if (type == 118 || type == 101) {
          return Validators.required
        }
        break;

      case 'url':
        if (type == 102 || type == 137
          || type == 138) {
          return Validators.required
        }
        break;

      case 'id_db':
        if (type == 6 || type == 12
          || type == 15 || type == 113
          || type == 121 || type == 3) {
          return Validators.required
        }
        break;

      case 'bd_table_autocomplete':
        if (type == 6 || type == 12
          || type == 15 || type == 113
          || type == 121 || type == 3) {
          return Validators.required
        }
        break;

      case 'bd_field_autocomplete':
        if (type == 6 || type == 12
          || type == 15 || type == 113
          || type == 121) {
          return Validators.required
        }
        break;

      case 'id_query_autocomplete':
        if (type == 12 || type == 3) {
          return Validators.required
        }
        break;

      case 'max_lengths':
        if (type == 6 || type == 113) {
          return Validators.required
        }
        break;

      default:
        return null
        break;
    }
  }

  _filterAutocomplete(value: any, array: any, field, name = "internal_name") {
    const filterValue = value[name] ? value[name].toLowerCase() : value.toLowerCase();
    if(array && array.length && array.length > 0) {
      let result = array.filter(option => option[name].toLowerCase().includes(filterValue))
      if (field == 'bd_table_autocomplete') {
        this.filteredOptionsLengthBdTable = result.length;
        return this.filteredOptionsLengthBdTable == 0
          ? result.slice()
          : (this.filteredOptionsLengthBdTable <= 200 ? result : result.slice(0, 199));
      } else if (field == 'bd_field_autocomplete') {
        this.filteredOptionsLengthBdField = result.length;
        return this.filteredOptionsLengthBdField == 0
          ? result.slice()
          : (this.filteredOptionsLengthBdField <= 200 ? result : result.slice(0, 199));
      } else return result;
    } else return [];
  }

  filterFunctionalTypes() {
    this.filteredFunctionalTypes = [];
    this.genericService.cloneVariable(this.arrayElementsTipo).forEach((item: any) => {
      if (item.form_field == 1) { this.filteredFunctionalTypes.push(item) }
    })
  }

  // Filter Status based on functional types
  filterStatusOptions(type: any) {
    console.log('type----', type, this.genericService.cloneVariable(type));
    this.filteredFunctionalStatus = [];
    if (type != 6 && type != 12 && type != 15 && type != 113 && type != 121 && type != 146) {
      this.genericService.cloneVariable(this.functionalStatus).forEach((item: any) => {
        if (item.id_functional_status == 1 || item.id_functional_status == 2) {
          this.filteredFunctionalStatus.push(item)
        }
      });
    } else {
      this.filteredFunctionalStatus = this.functionalStatus
    }
  }

  conditionDependingOfFuntionalTypes(element: any, type: any) {
    switch (element) {

      case 'sort':
        if (type == 9) {
          return true
        }
        break;

      case 'id_functional_type':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 11 || type == 117
          || type == 4 || type == 99
          || type == 105 || type == 10
          || type == 7 || type == 96
          || type == 95 || type == 3
          || type == 100 || type == 9
          || type == 114 || type == 115
          || type == 116 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121 || type == 146
          || type == 3) {
          return true
        }
        break;

      case 'type':
        if (type == 5 || type == 101
          || type == 99 || type == 14
          || type == 96 || type == 9
          || type == 6 || type == 12 || type == 15 || type == 113) {
          return true
        }
        break;

      case 'id_functional_status_general':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 102
          || type == 117 || type == 4
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 140 || type == 85 || type == 158 || type == 159
          || type == 86 || type == 87
          || type == 88 || type == 98 || type == 141) {
          return true
        }
        break;

      case 'class_custom_original_creator':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 114 || type == 115
          || type == 116 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }

      case 'class':
        if (type == 5 || type == 6 || type == 12) {
          return true
        }
        break;

      case 'style':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 114 || type == 115
          || type == 116 || type == 4 || type == 141
          || type == 140 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'hide':
          return true
        break;

      case 'width':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 114 || type == 115
          || type == 116 || type == 4 || type == 141
          || type == 140 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'id_product_mkt_autocomplete':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'id_product_mkt_no_autocomplete':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'id_condition_autocomplete':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116 || type == 141
          || type == 4 || type == 140 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'condition_internal_routing_autocomplete':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'condition_session_active':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'dev':
        if (type == 8 || type == 89
          || type == 5 || type == 101
          || type == 137 || type == 138
          || type == 91 || type == 118
          || type == 102 || type == 14
          || type == 11 || type == 117
          || type == 99 || type == 105
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 115 || type == 116
          || type == 4 || type == 140
          || type == 85 || type == 86 || type == 158 || type == 159
          || type == 87 || type == 88 || type == 141
          || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'buttonType':
        if (type == 5) {
          return true
        }
        break;
      case 'tabType':
        if (type == 8) {
          return true
        }
        break;
      case 'min_rows':
        if (type == 8 || type == 6) {
          return true
        }
        break;

      case 'max_rows':
        if (type == 6) {
          return true
        }
        break;

      case 'max_lengths':
        if (type == 6) {
          return true
        }
        break;

      case 'max_lengths2':
      if (type == 113) {
        return true
      }
      break;

      case 'form_field_empty':
        if (type == 6 || type == 12 || type == 121) {
          return true
        }
        break;

      case 'autoload_selection':
        if (type == 12) {
          return true
        }
        break;

      case 'profiles_permissions_autocomplete':
        if (type == 89 || type == 5
          || type == 101 || type == 137
          || type == 138 || type == 91
          || type == 118 || type == 102
          || type == 14 || type == 11
          || type == 117 || type == 99
          || type == 10 || type == 7
          || type == 96 || type == 95
          || type == 3 || type == 100
          || type == 9 || type == 114
          || type == 4 || type == 105
          || type == 115 || type == 116
          || type == 140 || type == 85 || type == 158 || type == 159
          || type == 86 || type == 87 || type == 141
          || type == 88 || type == 98 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'label':
        if (type == 5 || type == 14 ||
          type == 4 || type == 105 ||
          type == 7 || type == 95 ||
          type == 9 || type == 114
          || type == 115 || type == 86 || type == 141
          || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'tooltip':
        if (type == 5 || type == 101 || type == 4
          || type == 137 || type == 138
          || type == 102 || type == 11
          || type == 117 || type == 105
          || type == 7 || type == 95
          || type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'badge':
        if (type == 5) {
          return true
        }
        break;

      case 'id_table_relations':
        if (type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'param':
        if (type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'param_intern':
        if (type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'multiple':
        if (type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'id_function_autocomplete':
        if (type == 3 || type == 5 || type == 137 ||
          type == 7 || type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'icon':
        if (type == 5 || type == 4
          || type == 105 || type == 7
          || type == 95 || type == 9 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'text':
        if (type == 101 || type == 118
          || type == 14 || type == 7
          || type == 3 || type == 114 || type == 141
          || type == 115 || type == 6 || type == 12 || type == 15) {
          return true
        }
        break;

      case 'description':
        if (type == 6 || type == 12 || type == 15 || type == 113 || type == 141) {
          return true
        }
        break;

      case 'url':
        if (type == 101 || type == 137
          || type == 138 || type == 102
          || type == 114 || type == 115 || type == 141) {
          return true
        }
        break;

      case 'iframe_fullscreen':
        if (type == 138) {
          return true
        }
        break;

      case 'carousel_num_scroll_s':
        if (type == 117) {
          return true
        }
        break;

      case 'carousel_num_scroll_xs':
        if (type == 117) {
          return true
        }
        break;

      case 'carousel_num_visible_s':
        if (type == 117) {
          return true
        }
        break;

      case 'carousel_num_visible_xs':
        if (type == 117) {
          return true
        }
        break;

      case 'show_total_graphs':
        if (type == 14) {
          return true
        }
        break;

      case 'id_query_autocomplete':
        if (type == 14 || type == 3 || type == 85 || type == 98 || type == 158 || type == 159 || type == 12) {
          return true
        }
        break;

      case 'carousel_num_visible':
        if (type == 117) {
          return true
        }
        break;

      case 'carousel_num_scroll':
        if (type == 117) {
          return true
        }
        break;

      case 'carousel_circular':
        if (type == 117) {
          return true
        }
        break;

      case 'show_buttons_carousel':
        if (type == 117) {
          return true
        }
        break;

      case 'expansion_start_opened':
        if (type == 7) {
          return true
        }
        break;

      case 'id_db':
        if (type == 3 || type == 9 || type == 85 || type == 98 || type == 158 || type == 159 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121) {
          return true
        }
        break;

      case 'bd_table_autocomplete':
        if (type == 85 || type == 98 || type == 158 || type == 159 || type == 6 || type == 12 || type == 15 || type == 113 || type == 121 || type == 3 || type == 9) {
          return true
        }
        break;

      case 'bd_field_autocomplete':
        if (type == 6 || type == 12 || type == 15 || type == 113 || type == 121 || type == 9) {
          return true
        }
        break;

      case 'close_dialog_out':
        if (type == 85 || type == 158 || type == 159) {
          return true
        }
        break;

      case 'search':
        if (type == 3) {
          return true
        }
        break;

      case 'pagination':
        if (type == 3) {
          return true
        }
        break;

      case 'canExportTables':
        if (type == 3) {
          return true
        }
        break;

      case 'color_card':
        if (type == 100) {
          return true
        }
        break;

      case 'hide_booleans_false':
        if (type == 3) {
          return true
        }
        
        break;
      case 'align':
        if (type == 11 || type == 91) {
          return true
        }
        break;

      case 'text_align':
        if (type == 101 || type == 5) {
          return true
        }
        break;

      case 'height':
        return true
        break;

      default:
        return false
        break;
    }
  }

  formValueSubscribe(value, element) {
    console.log('haha 1', value)
    console.log('haha 2', element)
    for (let key in value) {
      for (let key2 in element) {
        if (key === key2) {
          element[key2] = value[key];
          Object.keys(this.defaultLanguageElement).forEach(item => {
            if (item == key2) {
              let founded = false;
              for (let i in element['functional_area_language']) {
                if (element['functional_area_language'][i]['id_language'] == this.currentLanguage) {
                  element['functional_area_language'][i][key2] = value[key];
                  element['functional_area_language'][i]['mode_type'] = 'update';
                  founded = true;
                  break;
                }
              }
              if(!founded) {
                let obj = this.genericService.cloneVariable(this.defaultLanguageElement);
                obj['id_language'] = this.currentLanguage;
                obj[key2] = value[key];
                obj['mode_type'] = 'update';
                element['functional_area_language'].push(obj);
              }
            }
          })
          if (element['mode_type'] == undefined) {
            element['mode_type'] = 'update';
          }
        }
      }
    }
    element['validatedFrontend'] = false;
    this.genericService.asignFrontend([element], this.arrayElementsGenericCreator[0]['id_funtional_area']);
    this.genericService.findFormAttributesLoop([element]);
    this.addHistoryRecord();
  }

  addAsInsert(child) {
    for (let i in child) {
      child[i]['mode_type'] = "insert";
      if (child[i]['child'] && child[i]['child'].length > 0) this.addAsInsert(child[i]['child']);
    }
  }

  addHistoryRecord() {
    if (this.currentRecordIndex !== this.arrayElementsGenericCreatorRecord.length - 1) {
      this.arrayElementsGenericCreatorRecord.length = this.currentRecordIndex;
    }
    this.currentRecordIndex = this.arrayElementsGenericCreatorRecord.push(this.genericService.cloneVariable(this.arrayElementsGenericCreator)) - 1;
    console.log('History Array', this.arrayElementsGenericCreatorRecord);
    console.log('this.currentRecordIndex', this.currentRecordIndex);
  }

  filterTypes(id_functional_type) {
    console.log(id_functional_type, ">>>>>>>>>>>> id_functional_type");
    this.functionalSubtypes = this.genericService.cloneVariable(this.functional_subtype)
    setTimeout(() => {
      if (this.lastId_functional_type != id_functional_type) {
        this.lastId_functional_type = id_functional_type;
        this.functionalSubtypes = this.functional_subtype.filter(item => {
          return item.id_functional_type === id_functional_type
        })
      }
    });
  }

  resetOptions() {
    this.currentElement = 0;
    this.actualSelectedElement = undefined;
    this.actualSelectedSubElement = undefined;
    this.isAddingElement = false;
  }

  addElementIdsTMPLoop(array) {
    for (let i in array) {
      array[i]['idTmp'] = this.tmpId;
      array[i]['creatingMode'] = true;
      ++this.tmpId;
      if (array[i]['child'] && array[i]['child'].length > 0) this.addElementIdsTMPLoop(array[i]['child']);
    }
  }

  pushNewElement(object, idParent, elements) {
    if(elements && elements.length && elements.length > 0) {
      for (let item of elements) {
        if (item['idTmp'] === idParent) {
          this.pushNewElementInParentChilds(item, object);
          break;
        } else if (item['child'] !== undefined) {
          this.pushNewElement(object, idParent, item['child']);
        }
      }
    }
  }

  pushNewElementInParentChilds(item, object) {
    object['order_general'] = item.child ? item.child.length + 1 : 0;
    if (item['child'] == undefined) item['child'] = [];
    item['child'].push(object)
    item['validatedFrontend'] = false;
    this.genericService.asignFrontend(item['child'], this.arrayElementsGenericCreator[0]['id_funtional_area']);
    this.genericService.findFormAttributesLoop(item['child']);
    if(object['child']) this.addAsInsert(object['child']);
    this.addHistoryRecord();
  }

  deleteFloatingElement(element: any, i) {
    this.expandedFloatingIndex = null;
    this.expandedFloatingElement = null;
    this.arrayFloatingElements.splice(i, 1);
    this.deleteElement(element);
  }

  deleteElement(element: any) {
    element.id_functional_status_general = 2;
    element.hide = 1;
    element.hide_xs = 1;
    element.hide_s = 1;
    element.hide_m = 1;
    element.hide_l = 1;
    element.hide_xl = 1;
    element.mode_type = 'delete';
    this.addHistoryRecord();
  }

  copyElement(element: any) {
    this.copiedElement = this.genericService.cloneVariable(element);
    this.genericService.openSnackBar("Element is copied.", 7000, ['green-snackbar']);
  }

  resetElement(element: any) {
    element['id_functional_area'] = null;
    element.id_functional_status_mkt = 1;
    element.creatingMode = true;
    element['class_custom'] += ' creating-mode';
    element['mode_type'] = 'insert';
    element['validatedFrontend'] = false;
    if(element['child'] && Array.isArray(element['child']) && element['child'].length > 0) {
      for(let item of element['child']) {
        this.resetElement(item);
      }
    }
  }

  pasteElement(idParent: any, isDSB = false) {
    let copiedElement = this.genericService.cloneVariable(this.copiedElement);
    this.resetElement(copiedElement);
    if(isDSB) {
      this.arrayFloatingElements.push(copiedElement)
      this.pushNewElementInParentChilds(this.arrayElementsGenericCreator[0], copiedElement);
    } else this.pushNewElement(copiedElement, idParent, this.arrayElementsGenericCreator);
    this.addElementIdsTMPLoop(this.arrayElementsGenericCreator);

    // solo para testear que se copio el elemento correctamente, eliminar cuando se termine de testear*********************
    console.log('**COPIED ELEMENT** IN GENERIC-CREATOR SERVICE:', copiedElement);
    console.log('**COPIED ELEMENT** MODE TYPE IN GENERIC-CREATOR SERVICE:', copiedElement['mode_type']);

    if (copiedElement['child'] && copiedElement['child'].length > 0) {
      console.log('**COPIED ELEMENT** CHILDREN IN GENERIC-CREATOR SERVICE:', copiedElement['child'])
      for (let item of copiedElement['child']) {
        console.log('**COPIED ELEMENT** CHILD MODE TYPE IN GENERIC-CREATOR SERVICE:', item['mode_type']);
      }
    }
    //*********************************************************************************************************************

  }

  updateStructure() {
    this.genericService.devModeGeneric = false;
    this.genericService.devModeGenericFunction();
    let elementsTemplate = this.stringifyStructure(this.genericService.cloneVariable(this.arrayElementsGenericCreator));
    console.log(elementsTemplate, this.arrayElementsGenericCreator, "this.arrayElementsGenericCreator");
    let json = {
      elementsTemplate: elementsTemplate,
      isStatic: this.isStatic
    }
    this.endpointService.updateGenericCreatorStructure(json).subscribe(res => {
      console.log('update api', res);
      this.genericService.openSnackBar("Structure updated successfully.", 7000, ['green-snackbar']);
      this.genericService.refreshStructure(0);
    });
  }

  stringifyStructure(elements) {
    for (let i in elements) {
      if (elements[i]['form']) elements[i]['form'] = null;
      if (elements[i]['formStep']) elements[i]['formStep'] = null;
      if (elements[i]['idFAParams']) elements[i]['idFAParams'] = null;
      if (elements[i]['idFAParamsInterns']) elements[i]['idFAParamsInterns'] = null;
      if (elements[i]['styleParsed']) elements[i]['styleParsed'] = null;
      if (elements[i]['formCreator']) elements[i]['formCreator'] = null;
      if (elements[i]['tmp_value']) elements[i]['tmp_value'] = null;

      if(elements[i]['id_functional_type'] == 117) {
        delete elements[i]['items'];
      }

      for (let j in elements[i]['excluding_data_type_fa_restrictions']) {
        elements[i]['excluding_data_type_fa_restrictions'][j]['excludingFaRestrictionForm'] = null;
      }
      for (let j in elements[i]['functional_area_restrictions']) {
        elements[i]['functional_area_restrictions'][j]['restrictionForm'] = null;
      }
      elements[i]['child'] = this.stringifyStructure(elements[i]['child']);
    }
    return elements;
  }

  moveElements(array: any, id_functional_area: any, element: any, direction: any) {
    for (let i in array) {
      if (array[i]?.['id_functional_area'] == id_functional_area) {
        for (let j in array[i]?.['child']) {
          if (element['idTmp'] == array[i]?.['child'][j]['idTmp']) {

            let valuetorIndex = +this.genericService.cloneVariable(j);
            if (direction == 'up') valuetorIndex = valuetorIndex - 1;
            else valuetorIndex = valuetorIndex + 1;

            let valuesChange = ["order_general", "order_auto_cron"];
            for (let field of valuesChange) {
              if (array[i]?.['child'][valuetorIndex]) {
                let order_general = this.genericService.cloneVariable(array[i]?.['child'][valuetorIndex][field]);
                let order_general2 = this.genericService.cloneVariable(array[i]['child'][j][field]);
                array[i]['child'][valuetorIndex][field] = order_general2;
                array[i]['child'][j][field] = order_general;

              }
            }

            let item = this.genericService.cloneVariable(array[i]?.['child'][valuetorIndex]);
            array[i]['child'][valuetorIndex] = this.genericService.cloneVariable(array[i]['child'][j]);
            array[i]['child'][j] = item;
            if (array[i]['child'][j]['mode_type'] === undefined) array[i]['child'][j]['mode_type'] = "update";
            if (array[i]['child'][valuetorIndex]['mode_type'] === undefined) array[i]['child'][valuetorIndex]['mode_type'] = "update";
            break;
          }
        }
        break;
      } else {
        if (array[i]?.['child'] && array[i]['child'].length > 0) this.moveElements(array[i]['child'], id_functional_area, element, direction)
      }
    }
  }

  prepareNewEleToAdd(newElement) {
    newElement.idTmp = this.tmpId;
    newElement.id_functional_status_mkt = 1;
    newElement.creatingMode = true;
    newElement['class_custom'] += ' creating-mode';
    newElement['mode_type'] = 'insert';
    newElement['validatedFrontend'] = false;
    newElement['parent_has_form'] = false;
    ++this.tmpId;
    if(this.expandedFloatingElement && this.expandedFloatingElement['id_functional_type']) {
      newElement['is_dsb_child'] = true;
      newElement['is_dialog_child'] = this.expandedFloatingElement['id_functional_type'] == 85;
      newElement['is_bottomsheet_child'] = this.expandedFloatingElement['id_functional_type'] == 98;
      newElement['is_snackbar_child'] = this.expandedFloatingElement['id_functional_type'] == 158;
      newElement['is_advicebar_child'] = this.expandedFloatingElement['id_functional_type'] == 159;
    }
  }

  selectLanguage(lang) {
    this.actualSelectedElement = null;
    console.log('this.arrayElementsGenericCreator', this.arrayElementsGenericCreator);
    this.checkLanguageLoop(this.arrayElementsGenericCreator, lang)
  }

  checkLanguageLoop(array, language) {
    for (let i in array) {
      let langObj = this.genericService.cloneVariable(this.defaultLanguageElement);
      langObj['id_language'] = language;
      langObj['id_functional_area'] = array[i]['id_functional_area'];
      let founded = false;
      if (array[i]['functional_area_language'] == undefined) {
        array[i]['functional_area_language'] = []
      }
      for (let k in array[i]['functional_area_language']) {
        if (array[i]['functional_area_language'][k].id_language == language) {
          langObj = array[i]['functional_area_language'][k];
          founded = true;
          break;
        }
      }
      if (!founded) {
        array[i]['functional_area_language'].push(langObj)
      }
      Object.keys(langObj).forEach(item => {
        array[i][item] = langObj[item];
      })
      if (array[i]['child'] && array[i]['child'].length > 0) this.checkLanguageLoop(array[i]['child'], language);
      array[i]['validatedFrontend'] = false;
    }
    this.genericService.asignFrontend(array, this.arrayElementsGenericCreator[0]['id_funtional_area']);
    this.genericService.findFormAttributesLoop(array);
  }

  getTablesFromDatabase(id_db: any) {
    this.endpointService.getTablesFromDatabase(id_db).subscribe(data => {
      this.tables[id_db] = data['response'];
    })
  }

  getFieldsFromTable(id_db: any, table: any) {
    this.endpointService.getFieldsFromTable(id_db, table).subscribe(data => {
      if (this.fields[id_db] === undefined) this.fields[id_db] = {};
      this.fields[id_db][table] = data['response'];
    })
  }


  editOption(element: any) {
    if (element && !element['creatingMode']) return;
    this.actualSelectedElement = undefined;
    this.isSelectingElement = true;
    setTimeout(() => {
      this.actualSelectedElement = element;
      this.isSelectingElement = false;
      this.filterStatusOptions(element['id_functional_type']);
      this.isAddingElement = false;
      this.expandedIndex = 1;
      this.hideOptionsCreator = false;
      console.log('ELEMENT TO EDIT: ', this.actualSelectedElement, this.activeTabIndex);
    });
  }

  refreshTables(array) {
    for(let i in array) {
      if(array[i]['id_functional_type'] && array[i]['id_functional_type'] == 3) {
        array[i]['showCreator'] = true;
        setTimeout(() => {
          array[i]['showCreator'] = false;
        });
      } else if(array[i]['child']) {
        this.refreshTables(array[i]['child']);
      }
    }
  }

  isDSB(element) {
    return element && element['isFloatingElement'] && element['isFloatingElement'] == 1;
  }

}
