import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GenericService } from "../../../../services/generic/generic.service";
import { AuthService } from '../../../../services/auth/auth.service';

interface Group {
  selectedColumn: string;
  subGroups: Group[];
  isDisabled: boolean;
  level:number;
}

@Component({
  selector: 'app-table-groups',
  templateUrl: './table-groups.component.html',
  styleUrls: ['./table-groups.component.scss']
})
export class TableGroupsComponent implements OnInit {

  public columns = [];
  public columnsInit = [];
  public loaded = false;
  public nameGroup = "Nuevo grupo";
  public editingGroup = null;
  public rootGroup: Group = { selectedColumn: null, subGroups: [], isDisabled: false, level: 1 };

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public _bottomSheetRef: MatBottomSheetRef<TableGroupsComponent>,
    public genericService: GenericService,
    public authService: AuthService
  ) {
    this.columnsInit = data.columns;
    this.editingGroup = data.editingGroup;
  }
  
  ngOnInit(): void {
    let cols = this.genericService.cloneVariable(this.columnsInit);
    this.nameGroup = this.editingGroup ? this.editingGroup['name'] : this.nameGroup;
    console.log(this.editingGroup);
    this.rootGroup = this.editingGroup && this.editingGroup['groups'] ? this.editingGroup['groups'] : this.rootGroup;
    for(let i in cols) {
      let obj = {};
      if(cols[i] != "tmp_param" && cols[i] != "param_intern") {
        let column = this.genericService.cloneVariable(cols[i]);
        obj['column'] = column;
        let idFaSplited = this.genericService.cloneVariable(column).split('-');
        if(idFaSplited[0] === undefined || idFaSplited[0] == "selectorCheckbox") continue;
        let idFa = null;
        if(idFaSplited[0]) idFa = this.genericService.findElementWithId(idFaSplited[0], false, false, true);
        if(!idFa) continue;
        if (idFa['hide_initial'] === undefined) idFa['hide_initial'] = this.genericService.cloneVariable(idFa['hide']);
        if(idFa['hide_initial'] == 1 && this.authService.checkUserIsDeveloping != 1) continue;
        if(idFa) {
          if(idFa['label']) obj["label"] = idFa['label'];
        }
        if(idFa["type"] == "image") continue;
        if(!obj["label"]) continue;
        this.columns.push(obj);
      }
    }
    if(this.rootGroup.subGroups && this.rootGroup.subGroups.length > 0) {
      this.assignLevel(this.rootGroup, 1);
    }
    this.loaded = true;
  }

  assignLevel(group: Group, level: number) {
    group.level = level;
    for (let subGroup of group.subGroups) {
      this.assignLevel(subGroup, level + 1);
    }
  }

  addSubGroup(group: Group) {
    const newGroup: Group = { selectedColumn: null, subGroups: [], isDisabled: false, level: group.level + 1 };
    group.subGroups.push(newGroup);
    group.isDisabled = true;
  }

  editGroup(group: Group) {
    group.isDisabled = false;
    // Elimina todos los subgrupos después de este grupo
    group.subGroups = [];
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss({ from: 3, groups: this.rootGroup, name: this.nameGroup });
  }
  
  filterTableValues() {
    this.checkGroup(this.rootGroup);
    this._bottomSheetRef.dismiss({ from: 2, groups: this.rootGroup, name: this.nameGroup });
  }

  resetFilterTableValues() {
    this.rootGroup = { selectedColumn: null, subGroups: [], isDisabled: false, level: 1 };
    this._bottomSheetRef.dismiss({ from: 1, groups: this.rootGroup, name: this.nameGroup });
  }

  // Método para obtener las columnas disponibles evitando repeticiones
  availableColumns(currentGroup: Group): any[] {
    const selectedColumns = this.getSelectedColumns(this.rootGroup, currentGroup);
    return this.columns.filter(c => !selectedColumns.includes(c.column) || c.column === currentGroup.selectedColumn);
  }

  // Método recursivo para obtener las columnas seleccionadas
  getSelectedColumns(group: Group, currentGroup: Group): string[] {
    let columns = [];
    if (group !== currentGroup && group.selectedColumn) {
      columns.push(group.selectedColumn);
    }
    for (let subGroup of group.subGroups) {
      columns = columns.concat(this.getSelectedColumns(subGroup, currentGroup));
    }
    return columns;
  }

  deleteGroup(groupParent) {
    if(groupParent) {
      console.log(groupParent);
      if(groupParent.subGroups) {
        groupParent.subGroups = [];
      }
      groupParent.isDisabled = false;
    } else {
      this.rootGroup = { selectedColumn: null, subGroups: [], isDisabled: false, level: 1 };
    }
  }

  checkGroup(group: Group) {
    if(group.subGroups && group.subGroups[0]) {
      if(group.subGroups[0].selectedColumn === undefined) delete group.subGroups;
      else this.checkGroup(group.subGroups[0]);
    }
  }
}