<mat-accordion>
    <mat-expansion-panel *ngFor="let debugItem of insertsDebugList">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ debugItem.bd_table }}
        </mat-panel-title>
        <mat-panel-description>
          {{ debugItem.type }}
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div class="debug-details">
        <p *ngIf="debugItem.query">
          <strong>Query (simulada):</strong> {{ debugItem.query }}
        </p>
        <p *ngIf="debugItem.fakeId">
          <strong>ID simulado:</strong> {{ debugItem.fakeId }}
        </p>
        <p *ngIf="debugItem.database">
          <strong>Base de datos:</strong> {{ debugItem.database }}
        </p>
        <p *ngIf="debugItem.explanation">
          <strong>Explicación:</strong> {{ debugItem.explanation }}
        </p>
  
        <!-- Mostrar los valores del record -->
        <div *ngIf="debugItem.values">
          <strong>Values:</strong>
          <pre>{{ debugItem.values | json }}</pre>
        </div>
  
        <!-- Mostrar las relaciones (si existen) -->
        <ng-container *ngIf="debugItem.relations && debugItem.relations.length > 0">
          <h4>Relaciones asignadas:</h4>
          <div class="relation-item" *ngFor="let rel of debugItem.relations">
            <p>
              <strong>Campo origen:</strong> {{ rel.origin_field }}
            </p>
            <p>
              <strong>Tabla destino:</strong> {{ rel.tableHija }}
            </p>
            <p>
              <strong>Campo destino:</strong> {{ rel.dst_field }}
            </p>
            <p>
              <strong>Valor asignado:</strong> {{ rel.valorAsignado }}
            </p>
            <p>
              <strong>Valores generales:</strong>
              <span><pre>{{ rel.values | json }}</pre></span>
            </p>
            <hr />
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>