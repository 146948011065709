import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-add-generic-element-dialog',
  templateUrl: './add-generic-element-dialog.component.html',
  styleUrls: ['./add-generic-element-dialog.component.css']
})
export class AddGenericElementDialogComponent implements OnInit {

  searchText: any;
  filteredArray: any;
  selectedItem: any;
  selectedItemClass: any;
  idParent: any;
  isLinear = false;
  newElement: any;

  constructor(public genericCreatorService: GenericCreatorService,
    public functionsService: FunctionsService,
    public genericService: GenericService,
    public dialogRef: MatDialogRef<AddGenericElementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.resetSelectedElement();
    console.log('Dataaa dialog add', this.data);
    this.idParent = this.data.idParent;
    this.filteredArray = [...this.genericCreatorService.arrayElementsTipo];
    this.newElement = this.functionsService.cloneVariable(this.genericCreatorService.defaultArray);
    console.log('NEW ELEMENT: ', this.newElement);
    this.genericCreatorService.prepareNewEleToAdd(this.newElement);
    this.newElement['parent_has_form'] = this.data.parent_has_form; 
  }

  /**
   * Filtering data based on search input value
   * @param event
   * @returns
   */
  filterData(event) {
    this.searchText = event?.target?.value
    // no search text, all records
    if (!this.searchText) {
      this.filteredArray = [...this.genericCreatorService.arrayElementsTipo];
      return;
    }

    const allData = [...this.genericCreatorService.arrayElementsTipo];
    const properties = Object.keys(allData[0]);
    if (this.searchText) {
      this.filteredArray = allData.filter((item: any) => {
        return properties.find((property) => {
          const valueString = item[property]?.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          return valueString?.includes(this.searchText.toLowerCase());
        })
          ? item
          : null;
      });
    }
  }

  resetSelectedElement() {
    this.genericCreatorService.actualSelectedElement = null;
    this.selectedItemClass = '';
    this.selectedItem = null;
    this.genericCreatorService.activeTabIndex = 0;
  }

  setActiveItem(item, type, index, stepper: MatStepper) {
    this.selectedItemClass = type + index;
    this.selectedItem = item;
    
    this.newElement.id_functional_type = item.id_functional_type;
    this.genericCreatorService.id_functional_type = item.id_functional_type;
    this.genericCreatorService.filterStatusOptions(this.newElement['id_functional_type']);
    // this.genericCreatorService.filterTypes(this.newElement['id_functional_type']);
    if (this.newElement['id_functional_type'] == 137) {
      this.newElement['type'] = 57
    } else if (this.newElement['id_functional_type'] == 138) {
      this.newElement['type'] = 56
    }
      this.genericCreatorService.expandedIndex = 1;
      this.genericCreatorService.hideOptionsCreator = false;
      if (this.selectedItem.id_functional_type_child !== 0) {
        this.genericCreatorService.isAddingElement = true;
      } else {
        this.genericCreatorService.isAddingElement = false;
      }
      stepper.next();
  }

  addItemToArray() {
    if (!this.newElement.formCreator.valid) {
      this.genericService.openSnackBar("¡Vaya, parece que hay campos obligatorios sin completar!", 7000, ['red-snackbar']);
      return
    }
    console.log('>>>>ELEMENT ADDED TO ARRAY:', this.newElement, this.genericCreatorService.arrayElementsGenericCreator);
    this.genericCreatorService.pushNewElement(this.newElement, this.idParent, this.genericCreatorService.arrayElementsGenericCreator);
    this.genericCreatorService.actualSelectedElement = this.newElement;
    this.dialogRef.close(true);
  }
}
